/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(document).foundation(); // Foundation JavaScript
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init

      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {
        // JavaScript to be fired on the about us page

        $('.cell-trigger').click(function(){
          var cell = $(this).data('cell'); 
          $(cell).toggleClass('active');
        });

        $('.demographics-section').each(function () {
            
            var section = '#' + $(this).attr('id');
            var totalItems = 0;

            $(section + ' .demographic-item').each(function () {

                var qty = parseInt( $(this).data('qty') );
                totalItems = totalItems + qty;
                return totalItems;
            });

            $(section + ' h3 span').html('(' + totalItems + ')');

            $(section + ' .demographic-item').each(function () {

                var fill = 0;
                var qty = parseInt( $(this).data('qty') );
                fill = qty / totalItems * 100;
                
                $(this).find('.fill').width(fill + '%');
                $(this).find('.percentage').html( Math.round(fill) + '%');

            });

        });



      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

document.addEventListener("DOMContentLoaded", function () {
  //Main Nav toggle
  const nav = document.querySelector("#mobile-nav");
  const header = document.querySelector(".main-header");
  const opener = document.querySelector(".nav-opener");

  opener.addEventListener("click", function (event) {
    event.preventDefault();

    header.classList.toggle("nav-open");
    nav.classList.toggle("active");
  });
});

/*


$('.gallery').each(function( index ) {
  var gallery = $(this).attr('id');
  var lightbox = $('#' + gallery + ' a').simpleLightbox({ options });
});



// Simple Lightbox - Already Installed
var lightbox = $('.gallery-gallery a').simpleLightbox();

// Slick Slideshow - Already Installed
$('.home-slideshow').slick({
  infinite: true,
  cssEase: 'linear',
  autoplay: true,
  arrows: true,
  dots: true
});

// Smooth Scroll
var topOffset = 0;
$(".scroll-to, .scroll-to-li a").not('[href="#"]').not('[href="#0"]').click(function(t){if(location.pathname.replace(/^\//,"")===this.pathname.replace(/^\//,"")&&location.hostname===this.hostname){var e=$(this.hash);(e=e.length?e:$("[name="+this.hash.slice(1)+"]")).length&&(t.preventDefault(),$("html, body").animate({scrollTop:e.offset().top-topOffset},1e3,function(){var t=$(e);if(t.focus(),t.is(":focus"))return!1;t.attr("tabindex","-1"),t.focus()}))}});

*/
